// eslint-disable-next-line @github-ui/github-monorepo/filename-convention
import type {CopilotMarkdownExtension} from '../../extension'
import {allowAttributesHook, attributesSelector, dataAttrToPropName, parseJsonAttribute} from '../../utils'
import {
  CodeBlock,
  codeBlockAttributes,
  DataAttributesCodeBlock,
  languageAttribute,
  type CodeBlockProps,
} from './CodeBlock'
import styles from './CodeBlock.module.css'

import {visit} from 'unist-util-visit'

const codeblockAttribute = 'data-codeblock-props'
const codeblockProperty = dataAttrToPropName(codeblockAttribute)

export default function codeBlocksExtension(): CopilotMarkdownExtension {
  return {
    marked: [
      {
        extensions: [
          {
            name: 'code',
            renderer(token) {
              if (token.type !== 'code') return false

              const langToken = token.lang?.split(' ') ?? []
              const lang = langToken ? langToken[0] : ''

              const element = document.createElement('div')
              element.setAttribute(languageAttribute, lang)
              element.textContent = token.text
              element.classList.add(styles.blockContainer)

              return element.outerHTML
            },
          },
        ],
      },
    ],
    sanitizer: {
      attributeHook: allowAttributesHook(codeBlockAttributes),
      allowedClassNames: [styles.blockContainer],
    },
    react: {
      selector: attributesSelector(codeBlockAttributes),
      Component: DataAttributesCodeBlock,
    },
    transformMarkdown: tree =>
      visit(tree, 'code', node => {
        node.data = {
          hName: 'div',
          hProperties: {
            [codeblockProperty]: JSON.stringify({
              language: node.lang ?? '',
              children: node.value,
            } satisfies CodeBlockProps),
          },
          hChildren: [],
        }
      }),
    transformHtml: tree =>
      // Code nodes render as two nested elements (pre > code) by default, and setting the hName only overrides the
      // inner element. So we have to transform the html by removing the outer element
      visit(tree, 'element', (node, i, parent) => {
        const child = node.children?.[0]
        if (
          parent &&
          i !== undefined &&
          node.tagName === 'pre' &&
          node.children?.length === 1 &&
          child?.type === 'element' &&
          codeblockProperty in child.properties
        )
          parent.children.splice(i, 1, child)
      }),
    reactComponents: {
      div: (props, fallthrough) => {
        const codeblockProps = parseJsonAttribute<CodeBlockProps>(props, codeblockAttribute)
        if (!codeblockProps) return fallthrough

        return (
          // Extra markup mimics the block target and portal container output by the old renderer. When we remove
          // the old renderer and update the styles we can clean this up
          <div className={styles.blockContainer}>
            <div>
              <CodeBlock {...codeblockProps} />
            </div>
          </div>
        )
      },
    },
  }
}
