import {ReactComponentsExtension, renderFallthrough, type DataProps, type ReactMarkdownComponents} from './extension'
import {createElement} from 'react'

export function combineComponents(extensions: ReactComponentsExtension[]) {
  const result: ReactMarkdownComponents = {}

  // This looks more complicated than it really is. We are just iterating through the list of extensions and combining
  // the renderer functions to fall through to the previous extension if a function returns the `renderFallthrough` symbol
  for (const components of extensions)
    for (const [Element, renderer] of ReactComponentsExtension.entries(components))
      if (renderer) {
        const fallthroughRenderer = result[Element]

        // eslint-disable-next-line react/display-name
        result[Element] = props => {
          // `react-markdown` types don't define a definition for `data-*` props even though they are supported
          const renderResult = renderer(props as typeof props & DataProps, renderFallthrough)

          if (renderResult !== renderFallthrough) return renderResult

          // Fallthrough to next defined renderer, or render a plain element
          return fallthroughRenderer?.(props) ?? createElement(Element, props)
        }
      }

  return result
}
