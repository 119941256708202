import type {Config, HookEvent, SanitizeAttributeHookEvent} from 'dompurify'
import type {MarkedExtension} from 'marked'
import type {ComponentProps, ElementType, ReactNode} from 'react'
import type {ExtraProps} from 'react-markdown'

import type {Transformer} from 'unified'

import type {Root as MarkdownRoot} from 'mdast'

import type {Root as HtmlRoot} from 'hast'

export type ReactBlockAttributes = Partial<Record<string, string>> & {children: string}

export interface ReactBlockExtension {
  /**
   * Selector that matches rendered target elements for this block. Typically a simple attribute selector matching
   * the corresponding `data-` attributes for this block, like `"[data-block-property-a][data-block-property-b]"`.
   */
  selector: string
  /**
   * Component to render. All attributes on the target element will be passed to the component as string values.
   * Contents of the element will be passed as `children`.
   */
  Component: React.FC<ReactBlockAttributes>
  /**
   * Indicates that the block is an inline element.
   * @default false
   */
  inline?: boolean
}

export type SanitizeAttributeHook = (currentNode: Element, data: SanitizeAttributeHookEvent, config: Config) => void

/** @deprecated */
export type AfterSanitizeAttributeHook = (currentNode: Element, data: HookEvent, config: Config) => void

export interface SanitizerExtension {
  allowedTagNames?: string[]
  /** List of class names to allow. Often this is `Object.values(styles)` to allow all classes from a CSS Module. */
  allowedClassNames?: Array<string | RegExp>
  /** Use to allow certain attributes through. If an attribute should be kept, set `data.forceKeepAttr` to `true`. */
  attributeHook?: SanitizeAttributeHook
  /**
   * @deprecated Avoid. To determine if an attribute should be kept, use `attributeHook`. To manipulate the DOM,
   * use a `marked` extension.
   */
  afterAttributesHook?: AfterSanitizeAttributeHook
}

export const renderFallthrough = Symbol('renderFallthrough')

export type ElementName = Extract<ElementType, string>
type MarkdownComponentProps<Key extends ElementName> = ComponentProps<Key> & ExtraProps

export type DataProps = Partial<Record<`data-${string}`, string>>

export type ReactMarkdownComponents = {
  [Key in ElementName]?: (props: MarkdownComponentProps<Key>) => ReactNode
}

export type ReactComponentsExtensionRenderer<Key extends ElementName> = (
  props: MarkdownComponentProps<Key> & DataProps,
  // For convenience we provide the symbol here so you don't have to import it
  renderFallthroughSymbol: typeof renderFallthrough,
) => ReactNode | typeof renderFallthrough

export type ReactComponentsExtensionEntry<Key extends ElementName> = [Key, ReactComponentsExtensionRenderer<Key>]

export type ReactComponentsExtension = {
  [Key in ElementName]?: ReactComponentsExtensionRenderer<Key>
}
export const ReactComponentsExtension = {
  // Mapped types are great for objects where TS can easily understand that the key will correspond with the vaue,
  // but they fail when you need to iterate through the entries of that object. Inside each array entry the mapping
  // between key and value is lost - each entry would resolve to ReactComponentsExtensionEntry<ElementName>, which when
  // unpacked gives you the key type `ElementName` and value type `ReactComponentsExtensionRenderer<ElementName>`.
  // So we lie and act like there's only one entry in order to assert that the key and value are always related.
  entries: (extension: ReactComponentsExtension) =>
    Object.entries(extension) as Array<ReactComponentsExtensionEntry<'div'>>,
}

export type RemarkMarkdownTransformer = Transformer<MarkdownRoot, MarkdownRoot>
export type RehypeHtmlTransformer = Transformer<HtmlRoot, HtmlRoot>

/**
 * Extensions are defined as three separate items that each cover a step in the process. This unified object provides
 * all related code in one place.
 */
export interface CopilotMarkdownExtension {
  // --- both renderers ---

  /**
   * Modify the raw markdown text before it's processed.
   * Note: Avoid using this for complex transformations - prefer working with the AST instead.
   */
  preprocessMarkdown?: (markdown: string) => string

  // --- old renderer ---

  /**
   * Parser/tokenizer extension for Marked. This controls how Markdown is parsed and turned into HTML.
   * @deprecated Not supported by the new experimental Markdown editor.
   */
  marked?: MarkedExtension[]
  /**
   * Sanitizer configuration to allow the results of the `marked` step through the sanitizer. This should allow all
   * related attributes, class names, and tag names.
   * @deprecated Not supported by the new experimental Markdown editor.
   */
  sanitizer?: SanitizerExtension
  /**
   * For richer content, we can hydrate the bare HTML element into a React block with a React extension. If hydrating
   * with React, properties can be passed through `data-` attributes.
   * @deprecated Not supported by the new experimental Markdown editor.
   */
  react?: ReactBlockExtension

  // --- new renderer ---

  /**
   * Markdown syntax tree (mdast) transformer function to be run by Remark. Accepts the entire Markdown AST as input
   * and either mutates the input tree or returns the modified tree.
   *
   * The most common utility you will use for working with this AST is `unist-util-visit`, which provides a convenient
   * way to visit every node / 'walk the tree'.
   *
   * After this, the Markdown tree will be converted to the HTML tree using `mdast-util-to-hast`. Thus you can use
   * this transformer to customize how the Markdown is converted into HTML by adding special `hName`,
   * `hProperties`, and `hChildren` fields to the node's `data` object. For details, see
   * https://github.com/syntax-tree/mdast-util-to-hast?tab=readme-ov-file#fields-on-nodes. Note that when you set
   * property names, you must use the name format described in https://github.com/syntax-tree/hast?tab=readme-ov-file#propertyname.
   * `dataAttrToPropName` in `utils` will make this translation for you.
   */
  transformMarkdown?: RemarkMarkdownTransformer
  /**
   * HTML syntax tree (hast) transformer function to be run by Rehype. Accepts the entire Markdown AST as input
   * and either mutates the input tree or returns the modified tree.
   *
   * The most common utility you will use for working with this AST is `unist-util-visit`, which provides a convenient
   * way to visit every node / 'walk the tree'.
   */
  transformHtml?: RehypeHtmlTransformer
  /**
   * React component renderers for HTML elements. To fall through / skip the element, return the
   * `renderFallthrough` symbol. This would allow other plugins to catch this element and try their own rendering.
   * Typically, we will pass property objects via JSON and then decode them from the props with `parseJsonAttribute`
   * from `utils`.
   * @example
   * reactComponents={{
   *   "code": (props, fallthrough) => {
   *     const codeBlockProps = parseJsonAttribute<CodeBlockProps>(props, "data-codeblock-props")
   *     return prcodeBlockProps ? <CodeBlock {...codeBlockProps} /> : fallthrough
   * }}
   */
  reactComponents?: ReactComponentsExtension
}
