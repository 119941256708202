import {MarkdownViewer} from '@github-ui/markdown-viewer'
import {forwardRef, useMemo, useRef, useState} from 'react'

import {transformContentToHTML} from './render-markdown'

import codeBlocksExtension from './extensions/code-blocks'
import mathExtension from './extensions/math'
import linksExtension from './extensions/links'
import {useIsomorphicLayoutEffect, useRefObjectAsForwardedRef} from '@primer/react'
import {Block, type BlockProps} from './Block'
import {clsx} from 'clsx'
import styles from './MarkdownRenderer.module.css'
import streamingCursorExtension from './extensions/streaming-cursor'
import {ExtensionContext} from './extensions/ExtensionContext'
import {copilotFeatureFlags} from '@github-ui/copilot-chat/utils/copilot-feature-flags'

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import {combineComponents} from './combine-components'
import type {CopilotMarkdownExtension} from './extension'

// eslint-disable-next-line no-barrel-files/no-barrel-files
export {char as streamingChar} from './extensions/streaming-cursor'

export interface MarkdownRendererProps {
  /** Markdown to render. */
  markdown: string
  /** Callback when a link is clicked. `preventDefault` to stop navigation. */
  onLinkClick?: (event: MouseEvent) => void
  /** By default, links will open in a new tab. Set to `false` to open links in the current tab instead. */
  openLinksInCurrentTab?: boolean
  /** Class(es) to apply to the container element. */
  className?: string
  /** Extensions to apply to the markdown **/
  extensions?: readonly CopilotMarkdownExtension[]
  /** Show the streaming cursor. */
  isStreaming?: boolean
  /** Chat mode, for telemetry. */
  chatMode?: 'assistive' | 'immersive'
}

type InnerMarkdownRendererProps = Pick<MarkdownRendererProps, 'markdown' | 'extensions' | 'onLinkClick' | 'className'>

const emptyArray = [] as const

const MarkedMarkdownRenderer = forwardRef<HTMLDivElement, InnerMarkdownRendererProps>(function MarkedMarkdownRenderer(
  {markdown, onLinkClick, className, extensions = emptyArray},
  forwardedRef,
) {
  const html = useMemo(() => transformContentToHTML(markdown, extensions), [markdown, extensions])

  const ref = useRef<HTMLDivElement>(null)
  useRefObjectAsForwardedRef(forwardedRef, ref)

  // Memoize the MarkdownViewer so that when we trigger the second render by updating the blocks array, the
  // viewer doesn't disrupt our efforts by re-rendering its in DOM
  const markdownViewer = useMemo(
    () => (
      <MarkdownViewer
        onLinkClick={onLinkClick}
        verifiedHTML={html}
        ref={ref}
        className={clsx(styles.container, className)}
      />
    ),
    [className, html, onLinkClick],
  )

  // We can't render blocks on the first render because they depend on (and modify) the rendered HTML, so we store
  // blocks in state and update the array in an effect to trigger a second render
  const [blocks, setBlocks] = useState<readonly BlockProps[]>(emptyArray)
  useIsomorphicLayoutEffect(() => {
    if (!ref.current) {
      setBlocks(emptyArray)
      return
    }

    const result: BlockProps[] = []

    for (const {react} of extensions)
      if (react)
        for (const target of ref.current.querySelectorAll<HTMLDivElement>(react.selector))
          result.push({
            target,
            Component: react.Component,
            inline: react.inline,
          })

    setBlocks(result)

    // We want to reinject the blocks every time MarkdownViewer re-renders and clears the blocks
  }, [markdownViewer])

  return (
    <>
      {markdownViewer}

      {blocks.map((props, index) => (
        // It's safe to use indexes as keys here since the order is consistent across renders
        // eslint-disable-next-line @eslint-react/no-array-index-key
        <Block {...props} key={index} />
      ))}
    </>
  )
})

const ReactMarkdownRenderer = forwardRef<HTMLDivElement, InnerMarkdownRendererProps>(function ReactMarkdownRenderer(
  {className, markdown, extensions = emptyArray},
  ref,
) {
  const remarkPlugins = [
    remarkGfm,
    ...extensions.map(e => (e.transformMarkdown ? () => e.transformMarkdown : undefined)).filter(e => !!e),
  ]
  const rehypePlugins = extensions.map(e => (e.transformHtml ? () => e.transformHtml : undefined)).filter(e => !!e)

  // It's extremely critical that this is properly memoized such that this DOES NOT rerun when `markdown` changes!!
  // `combineComponents` creates new render functions every time it runs, which causes the corresponding React
  // to fully remount. This will cause flickering and significant performance costs when streaming.
  // This means that `extensions` also must be safely memoized, both here and in all consumers.
  const components = useMemo(
    () => combineComponents(extensions.map(e => e.reactComponents).filter(e => !!e)),
    [extensions],
  )

  let preprocessedMarkdown = markdown
  for (const extension of extensions)
    preprocessedMarkdown = extension.preprocessMarkdown?.(preprocessedMarkdown) ?? preprocessedMarkdown

  return (
    <div ref={ref} className={clsx('markdown-body', styles.container, className)}>
      <ReactMarkdown remarkPlugins={remarkPlugins} rehypePlugins={rehypePlugins} components={components}>
        {preprocessedMarkdown}
      </ReactMarkdown>
    </div>
  )
})

export const MarkdownRenderer = forwardRef<HTMLDivElement, MarkdownRendererProps>(function MarkdownRenderer(
  {isStreaming, chatMode, extensions: additionalExtensions, openLinksInCurrentTab, ...props},
  ref,
) {
  const extensions = useMemo(() => {
    const result: CopilotMarkdownExtension[] = [
      // More specific extensions must go last! So math (which uses `math` code blocks) and files (which use
      // `lang name=filename` code blocks) must follow the codeBlocksExtension
      codeBlocksExtension(),
      mathExtension(),
      linksExtension({openLinksInCurrentTab}),
    ]

    if (isStreaming) result.push(streamingCursorExtension())

    if (additionalExtensions) result.push(...additionalExtensions)

    return result
  }, [additionalExtensions, openLinksInCurrentTab, isStreaming])

  return (
    <ExtensionContext.Provider value={useMemo(() => ({isStreaming, chatMode}), [isStreaming, chatMode])}>
      {copilotFeatureFlags.newMarkdownRenderer ? (
        <ReactMarkdownRenderer ref={ref} extensions={extensions} {...props} />
      ) : (
        <MarkedMarkdownRenderer ref={ref} extensions={extensions} {...props} />
      )}
    </ExtensionContext.Provider>
  )
})

try{ MarkedMarkdownRenderer.displayName ||= 'MarkedMarkdownRenderer' } catch {}
try{ ReactMarkdownRenderer.displayName ||= 'ReactMarkdownRenderer' } catch {}
try{ MarkdownRenderer.displayName ||= 'MarkdownRenderer' } catch {}