export interface LanguageInfo {
  name: string
  color?: string
  wrap?: true
}

export function getLanguageInfo(name: string): LanguageInfo {
  const key = name.toUpperCase() as Uppercase<string>
  let info = languageMap[key]
  if (!info && languageAliases[key]) {
    info = languageMap[languageAliases[key]]
  }
  return info ?? {name}
}

export const docLanguages = new Set(['Markdown', 'MDX'])

/**
 * Often, the language name copilot uses is different than what we expect so we try to map them here.
 */
const languageAliases: Record<Uppercase<string>, Uppercase<string>> = {
  CPP: 'C++',
  CSHARP: 'C#',
  FSHARP: 'F#',
  VBNET: 'VISUAL BASIC .NET',
}

/**
 * Map of programming languages to colors.
 *
 * Extracted from https://github.com/github-linguist/linguist/blob/main/lib/linguist/languages.yml
 * Generated with this program:
 *   const fs = require('fs');
 *   const yaml = require('js-yaml');
 *   const input = fs.readFileSync('languages.yml').toString();
 *   const inLanguages = yaml.load(input);
 *   const outLanguages = {};
 *   for (const [k, v] of Object.entries(inLanguages)) {
 *     if (v.color) {
 *       const outKey = k.toUpperCase();
 *       const obj = ({color: v.color, name: k})
 *       if (v.wrap) obj.wrap = true
 *       outLanguages[outKey] = obj;
 *     }
 *   }
 *   fs.writeFileSync('languages_out.json', JSON.stringify(outLanguages));
 */
const languageMap: Record<string, LanguageInfo> = {
  '1C ENTERPRISE': {color: '#814CCC', name: '1C Enterprise'},
  '2-DIMENSIONAL ARRAY': {color: '#38761D', name: '2-Dimensional Array'},
  '4D': {color: '#004289', name: '4D'},
  ABAP: {color: '#E8274B', name: 'ABAP'},
  'ABAP CDS': {color: '#555e25', name: 'ABAP CDS'},
  'AGS SCRIPT': {color: '#B9D9FF', name: 'AGS Script'},
  AIDL: {color: '#34EB6B', name: 'AIDL'},
  AL: {color: '#3AA2B5', name: 'AL'},
  AMPL: {color: '#E6EFBB', name: 'AMPL'},
  ANTLR: {color: '#9DC3FF', name: 'ANTLR'},
  'API BLUEPRINT': {color: '#2ACCA8', name: 'API Blueprint'},
  APL: {color: '#5A8164', name: 'APL'},
  'ASP.NET': {color: '#9400ff', name: 'ASP.NET'},
  ATS: {color: '#1ac620', name: 'ATS'},
  ACTIONSCRIPT: {color: '#882B0F', name: 'ActionScript'},
  ADA: {color: '#02f88c', name: 'Ada'},
  'ADBLOCK FILTER LIST': {color: '#800000', name: 'Adblock Filter List'},
  'ADOBE FONT METRICS': {color: '#fa0f00', name: 'Adobe Font Metrics'},
  AGDA: {color: '#315665', name: 'Agda'},
  ALLOY: {color: '#64C800', name: 'Alloy'},
  'ALPINE ABUILD': {color: '#0D597F', name: 'Alpine Abuild'},
  'ALTIUM DESIGNER': {color: '#A89663', name: 'Altium Designer'},
  ANGELSCRIPT: {color: '#C7D7DC', name: 'AngelScript'},
  'ANSWER SET PROGRAMMING': {color: '#A9CC29', name: 'Answer Set Programming'},
  'ANT BUILD SYSTEM': {color: '#A9157E', name: 'Ant Build System'},
  ANTLERS: {color: '#ff269e', name: 'Antlers'},
  APACHECONF: {color: '#d12127', name: 'ApacheConf'},
  APEX: {color: '#1797c0', name: 'Apex'},
  'APOLLO GUIDANCE COMPUTER': {color: '#0B3D91', name: 'Apollo Guidance Computer'},
  APPLESCRIPT: {color: '#101F1F', name: 'AppleScript'},
  ARC: {color: '#aa2afe', name: 'Arc'},
  ASCIIDOC: {color: '#73a0c5', name: 'AsciiDoc', wrap: true},
  ASPECTJ: {color: '#a957b0', name: 'AspectJ'},
  ASSEMBLY: {color: '#6E4C13', name: 'Assembly'},
  ASTRO: {color: '#ff5a03', name: 'Astro'},
  ASYMPTOTE: {color: '#ff0000', name: 'Asymptote'},
  AUGEAS: {color: '#9CC134', name: 'Augeas'},
  AUTOHOTKEY: {color: '#6594b9', name: 'AutoHotkey'},
  AUTOIT: {color: '#1C3552', name: 'AutoIt'},
  'AVRO IDL': {color: '#0040FF', name: 'Avro IDL'},
  AWK: {color: '#c30e9b', name: 'Awk'},
  B4X: {color: '#00e4ff', name: 'B4X'},
  BASIC: {color: '#ff0000', name: 'BASIC'},
  BQN: {color: '#2b7067', name: 'BQN'},
  BALLERINA: {color: '#FF5000', name: 'Ballerina'},
  BATCHFILE: {color: '#C1F12E', name: 'Batchfile'},
  BEEF: {color: '#a52f4e', name: 'Beef'},
  BERRY: {color: '#15A13C', name: 'Berry'},
  BIBTEX: {color: '#778899', name: 'BibTeX'},
  BICEP: {color: '#519aba', name: 'Bicep'},
  BIKESHED: {color: '#5562ac', name: 'Bikeshed'},
  BISON: {color: '#6A463F', name: 'Bison'},
  BITBAKE: {color: '#00bce4', name: 'BitBake'},
  BLADE: {color: '#f7523f', name: 'Blade'},
  BLITZBASIC: {color: '#00FFAE', name: 'BlitzBasic'},
  BLITZMAX: {color: '#cd6400', name: 'BlitzMax'},
  BLUESPEC: {color: '#12223c', name: 'Bluespec'},
  'BLUESPEC BH': {color: '#12223c', name: 'Bluespec BH'},
  BOO: {color: '#d4bec1', name: 'Boo'},
  BOOGIE: {color: '#c80fa0', name: 'Boogie'},
  BRAINFUCK: {color: '#2F2530', name: 'Brainfuck'},
  BRIGHTERSCRIPT: {color: '#66AABB', name: 'BrighterScript'},
  BRIGHTSCRIPT: {color: '#662D91', name: 'Brightscript'},
  BROWSERSLIST: {color: '#ffd539', name: 'Browserslist'},
  C: {color: '#555555', name: 'C'},
  'C#': {color: '#178600', name: 'C#'},
  'C++': {color: '#f34b7d', name: 'C++'},
  'CAP CDS': {color: '#0092d1', name: 'CAP CDS'},
  CLIPS: {color: '#00A300', name: 'CLIPS'},
  CMAKE: {color: '#DA3434', name: 'CMake'},
  COLLADA: {color: '#F1A42B', name: 'COLLADA'},
  CSON: {color: '#244776', name: 'CSON'},
  CSS: {color: '#663399', name: 'CSS'},
  CSV: {color: '#237346', name: 'CSV'},
  CUE: {color: '#5886E1', name: 'CUE'},
  CWEB: {color: '#00007a', name: 'CWeb'},
  'CABAL CONFIG': {color: '#483465', name: 'Cabal Config'},
  CADDYFILE: {color: '#22b638', name: 'Caddyfile'},
  CADENCE: {color: '#00ef8b', name: 'Cadence'},
  CAIRO: {color: '#ff4a48', name: 'Cairo'},
  'CAIRO ZERO': {color: '#ff4a48', name: 'Cairo Zero'},
  CAMELIGO: {color: '#3be133', name: 'CameLIGO'},
  "CAP'N PROTO": {color: '#c42727', name: "Cap'n Proto"},
  CARBON: {color: '#222222', name: 'Carbon'},
  CEYLON: {color: '#dfa535', name: 'Ceylon'},
  CHAPEL: {color: '#8dc63f', name: 'Chapel'},
  CHUCK: {color: '#3f8000', name: 'ChucK'},
  CIRCOM: {color: '#707575', name: 'Circom'},
  CIRRU: {color: '#ccccff', name: 'Cirru'},
  CLARION: {color: '#db901e', name: 'Clarion'},
  CLARITY: {color: '#5546ff', name: 'Clarity'},
  'CLASSIC ASP': {color: '#6a40fd', name: 'Classic ASP'},
  CLEAN: {color: '#3F85AF', name: 'Clean'},
  CLICK: {color: '#E4E6F3', name: 'Click'},
  CLOJURE: {color: '#db5855', name: 'Clojure'},
  'CLOSURE TEMPLATES': {color: '#0d948f', name: 'Closure Templates'},
  'CLOUD FIRESTORE SECURITY RULES': {color: '#FFA000', name: 'Cloud Firestore Security Rules'},
  CODEQL: {color: '#140f46', name: 'CodeQL'},
  COFFEESCRIPT: {color: '#244776', name: 'CoffeeScript'},
  COLDFUSION: {color: '#ed2cd6', name: 'ColdFusion'},
  'COLDFUSION CFC': {color: '#ed2cd6', name: 'ColdFusion CFC'},
  'COMMON LISP': {color: '#3fb68b', name: 'Common Lisp'},
  'COMMON WORKFLOW LANGUAGE': {color: '#B5314C', name: 'Common Workflow Language'},
  'COMPONENT PASCAL': {color: '#B0CE4E', name: 'Component Pascal'},
  COQ: {color: '#d0b68c', name: 'Coq'},
  CRYSTAL: {color: '#000100', name: 'Crystal'},
  CSOUND: {color: '#1a1a1a', name: 'Csound'},
  'CSOUND DOCUMENT': {color: '#1a1a1a', name: 'Csound Document'},
  'CSOUND SCORE': {color: '#1a1a1a', name: 'Csound Score'},
  CUDA: {color: '#3A4E3A', name: 'Cuda'},
  CURRY: {color: '#531242', name: 'Curry'},
  CYLC: {color: '#00b3fd', name: 'Cylc'},
  CYPHER: {color: '#34c0eb', name: 'Cypher'},
  CYTHON: {color: '#fedf5b', name: 'Cython'},
  D: {color: '#ba595e', name: 'D'},
  D2: {color: '#526ee8', name: 'D2'},
  DM: {color: '#447265', name: 'DM'},
  DAFNY: {color: '#FFEC25', name: 'Dafny'},
  'DARCS PATCH': {color: '#8eff23', name: 'Darcs Patch'},
  DART: {color: '#00B4AB', name: 'Dart'},
  DATAWEAVE: {color: '#003a52', name: 'DataWeave'},
  'DEBIAN PACKAGE CONTROL FILE': {color: '#D70751', name: 'Debian Package Control File'},
  DENIZENSCRIPT: {color: '#FBEE96', name: 'DenizenScript'},
  DHALL: {color: '#dfafff', name: 'Dhall'},
  'DIRECTX 3D FILE': {color: '#aace60', name: 'DirectX 3D File'},
  DOCKERFILE: {color: '#384d54', name: 'Dockerfile'},
  DOGESCRIPT: {color: '#cca760', name: 'Dogescript'},
  DOTENV: {color: '#e5d559', name: 'Dotenv'},
  DUNE: {color: '#89421e', name: 'Dune'},
  DYLAN: {color: '#6c616e', name: 'Dylan'},
  E: {color: '#ccce35', name: 'E'},
  ECL: {color: '#8a1267', name: 'ECL'},
  ECLIPSE: {color: '#001d9d', name: 'ECLiPSe'},
  EJS: {color: '#a91e50', name: 'EJS'},
  EQ: {color: '#a78649', name: 'EQ'},
  EARTHLY: {color: '#2af0ff', name: 'Earthly'},
  EASYBUILD: {color: '#069406', name: 'Easybuild'},
  'ECERE PROJECTS': {color: '#913960', name: 'Ecere Projects'},
  ECMARKUP: {color: '#eb8131', name: 'Ecmarkup'},
  EDGE: {color: '#0dffe0', name: 'Edge'},
  EDGEQL: {color: '#31A7FF', name: 'EdgeQL'},
  EDITORCONFIG: {color: '#fff1f2', name: 'EditorConfig'},
  EIFFEL: {color: '#4d6977', name: 'Eiffel'},
  ELIXIR: {color: '#6e4a7e', name: 'Elixir'},
  ELM: {color: '#60B5CC', name: 'Elm'},
  ELVISH: {color: '#55BB55', name: 'Elvish'},
  'ELVISH TRANSCRIPT': {color: '#55BB55', name: 'Elvish Transcript'},
  'EMACS LISP': {color: '#c065db', name: 'Emacs Lisp'},
  EMBERSCRIPT: {color: '#FFF4F3', name: 'EmberScript'},
  ERLANG: {color: '#B83998', name: 'Erlang'},
  EUPHORIA: {color: '#FF790B', name: 'Euphoria'},
  'F#': {color: '#b845fc', name: 'F#'},
  'F*': {color: '#572e30', name: 'F*'},
  'FIGLET FONT': {color: '#FFDDBB', name: 'FIGlet Font'},
  FIRRTL: {color: '#2f632f', name: 'FIRRTL'},
  FLUX: {color: '#88ccff', name: 'FLUX'},
  FACTOR: {color: '#636746', name: 'Factor'},
  FANCY: {color: '#7b9db4', name: 'Fancy'},
  FANTOM: {color: '#14253c', name: 'Fantom'},
  FAUST: {color: '#c37240', name: 'Faust'},
  FENNEL: {color: '#fff3d7', name: 'Fennel'},
  'FILEBENCH WML': {color: '#F6B900', name: 'Filebench WML'},
  FLUENT: {color: '#ffcc33', name: 'Fluent'},
  FORTH: {color: '#341708', name: 'Forth'},
  FORTRAN: {color: '#4d41b1', name: 'Fortran'},
  'FORTRAN FREE FORM': {color: '#4d41b1', name: 'Fortran Free Form'},
  FREEBASIC: {color: '#141AC9', name: 'FreeBASIC'},
  FREEMARKER: {color: '#0050b2', name: 'FreeMarker'},
  FREGE: {color: '#00cafe', name: 'Frege'},
  FUTHARK: {color: '#5f021f', name: 'Futhark'},
  'G-CODE': {color: '#D08CF2', name: 'G-code'},
  GAML: {color: '#FFC766', name: 'GAML'},
  GAMS: {color: '#f49a22', name: 'GAMS'},
  GAP: {color: '#0000cc', name: 'GAP'},
  'GCC MACHINE DESCRIPTION': {color: '#FFCFAB', name: 'GCC Machine Description'},
  GDSCRIPT: {color: '#355570', name: 'GDScript'},
  GEDCOM: {color: '#003058', name: 'GEDCOM'},
  GLSL: {color: '#5686a5', name: 'GLSL'},
  GSC: {color: '#FF6800', name: 'GSC'},
  'GAME MAKER LANGUAGE': {color: '#71b417', name: 'Game Maker Language'},
  'GEMFILE.LOCK': {color: '#701516', name: 'Gemfile.lock'},
  GEMINI: {color: '#ff6900', name: 'Gemini', wrap: true},
  'GENERO 4GL': {color: '#63408e', name: 'Genero 4gl'},
  'GENERO PER': {color: '#d8df39', name: 'Genero per'},
  GENIE: {color: '#fb855d', name: 'Genie'},
  GENSHI: {color: '#951531', name: 'Genshi'},
  'GENTOO EBUILD': {color: '#9400ff', name: 'Gentoo Ebuild'},
  'GENTOO ECLASS': {color: '#9400ff', name: 'Gentoo Eclass'},
  'GERBER IMAGE': {color: '#d20b00', name: 'Gerber Image'},
  GHERKIN: {color: '#5B2063', name: 'Gherkin'},
  'GIT ATTRIBUTES': {color: '#F44D27', name: 'Git Attributes'},
  'GIT CONFIG': {color: '#F44D27', name: 'Git Config'},
  'GIT REVISION LIST': {color: '#F44D27', name: 'Git Revision List'},
  GLEAM: {color: '#ffaff3', name: 'Gleam'},
  'GLIMMER JS': {color: '#F5835F', name: 'Glimmer JS'},
  'GLIMMER TS': {color: '#3178c6', name: 'Glimmer TS'},
  GLYPH: {color: '#c1ac7f', name: 'Glyph'},
  GNUPLOT: {color: '#f0a9f0', name: 'Gnuplot'},
  GO: {color: '#00ADD8', name: 'Go'},
  'GO CHECKSUMS': {color: '#00ADD8', name: 'Go Checksums'},
  'GO MODULE': {color: '#00ADD8', name: 'Go Module'},
  'GO WORKSPACE': {color: '#00ADD8', name: 'Go Workspace'},
  'GODOT RESOURCE': {color: '#355570', name: 'Godot Resource'},
  GOLO: {color: '#88562A', name: 'Golo'},
  GOSU: {color: '#82937f', name: 'Gosu'},
  GRACE: {color: '#615f8b', name: 'Grace'},
  GRADLE: {color: '#02303a', name: 'Gradle'},
  'GRADLE KOTLIN DSL': {color: '#02303a', name: 'Gradle Kotlin DSL'},
  'GRAMMATICAL FRAMEWORK': {color: '#ff0000', name: 'Grammatical Framework'},
  GRAPHQL: {color: '#e10098', name: 'GraphQL'},
  'GRAPHVIZ (DOT)': {color: '#2596be', name: 'Graphviz (DOT)'},
  GROOVY: {color: '#4298b8', name: 'Groovy'},
  'GROOVY SERVER PAGES': {color: '#4298b8', name: 'Groovy Server Pages'},
  HAPROXY: {color: '#106da9', name: 'HAProxy'},
  HCL: {color: '#844FBA', name: 'HCL'},
  HLSL: {color: '#aace60', name: 'HLSL'},
  HOCON: {color: '#9ff8ee', name: 'HOCON'},
  HTML: {color: '#e34c26', name: 'HTML'},
  'HTML+ECR': {color: '#2e1052', name: 'HTML+ECR'},
  'HTML+EEX': {color: '#6e4a7e', name: 'HTML+EEX'},
  'HTML+ERB': {color: '#701516', name: 'HTML+ERB'},
  'HTML+PHP': {color: '#4f5d95', name: 'HTML+PHP'},
  'HTML+RAZOR': {color: '#512be4', name: 'HTML+Razor'},
  HTTP: {color: '#005C9C', name: 'HTTP'},
  HXML: {color: '#f68712', name: 'HXML'},
  HACK: {color: '#878787', name: 'Hack'},
  HAML: {color: '#ece2a9', name: 'Haml'},
  HANDLEBARS: {color: '#f7931e', name: 'Handlebars'},
  HARBOUR: {color: '#0e60e3', name: 'Harbour'},
  HARE: {color: '#9d7424', name: 'Hare'},
  HASKELL: {color: '#5e5086', name: 'Haskell'},
  HAXE: {color: '#df7900', name: 'Haxe'},
  HIVEQL: {color: '#dce200', name: 'HiveQL'},
  HOLYC: {color: '#ffefaf', name: 'HolyC'},
  'HOSTS FILE': {color: '#308888', name: 'Hosts File'},
  HY: {color: '#7790B2', name: 'Hy'},
  IDL: {color: '#a3522f', name: 'IDL'},
  'IGOR PRO': {color: '#0000cc', name: 'IGOR Pro'},
  INI: {color: '#d1dbe0', name: 'INI'},
  IDRIS: {color: '#b30000', name: 'Idris'},
  'IGNORE LIST': {color: '#000000', name: 'Ignore List'},
  'IMAGEJ MACRO': {color: '#99AAFF', name: 'ImageJ Macro'},
  IMBA: {color: '#16cec6', name: 'Imba'},
  'INNO SETUP': {color: '#264b99', name: 'Inno Setup'},
  IO: {color: '#a9188d', name: 'Io'},
  IOKE: {color: '#078193', name: 'Ioke'},
  ISABELLE: {color: '#FEFE00', name: 'Isabelle'},
  'ISABELLE ROOT': {color: '#FEFE00', name: 'Isabelle ROOT'},
  J: {color: '#9EEDFF', name: 'J'},
  'JAR MANIFEST': {color: '#b07219', name: 'JAR Manifest'},
  JCL: {color: '#d90e09', name: 'JCL'},
  JFLEX: {color: '#DBCA00', name: 'JFlex'},
  JSON: {color: '#292929', name: 'JSON'},
  'JSON WITH COMMENTS': {color: '#292929', name: 'JSON with Comments'},
  JSON5: {color: '#267CB9', name: 'JSON5'},
  JSONLD: {color: '#0c479c', name: 'JSONLD'},
  JSONIQ: {color: '#40d47e', name: 'JSONiq'},
  JAI: {color: '#ab8b4b', name: 'Jai'},
  JANET: {color: '#0886a5', name: 'Janet'},
  JASMIN: {color: '#d03600', name: 'Jasmin'},
  JAVA: {color: '#b07219', name: 'Java'},
  'JAVA PROPERTIES': {color: '#2A6277', name: 'Java Properties'},
  'JAVA SERVER PAGES': {color: '#2A6277', name: 'Java Server Pages'},
  'JAVA TEMPLATE ENGINE': {color: '#2A6277', name: 'Java Template Engine'},
  JAVASCRIPT: {color: '#f1e05a', name: 'JavaScript'},
  'JAVASCRIPT+ERB': {color: '#f1e05a', name: 'JavaScript+ERB'},
  'JEST SNAPSHOT': {color: '#15c213', name: 'Jest Snapshot'},
  'JETBRAINS MPS': {color: '#21D789', name: 'JetBrains MPS'},
  JINJA: {color: '#a52a22', name: 'Jinja'},
  JISON: {color: '#56b3cb', name: 'Jison'},
  'JISON LEX': {color: '#56b3cb', name: 'Jison Lex'},
  JOLIE: {color: '#843179', name: 'Jolie'},
  JSONNET: {color: '#0064bd', name: 'Jsonnet'},
  JULIA: {color: '#a270ba', name: 'Julia'},
  'JULIA REPL': {color: '#a270ba', name: 'Julia REPL'},
  'JUPYTER NOTEBOOK': {color: '#DA5B0B', name: 'Jupyter Notebook'},
  JUST: {color: '#384d54', name: 'Just'},
  KDL: {color: '#ffb3b3', name: 'KDL'},
  KRL: {color: '#28430A', name: 'KRL'},
  'KAITAI STRUCT': {color: '#773b37', name: 'Kaitai Struct'},
  KAKOUNESCRIPT: {color: '#6f8042', name: 'KakouneScript'},
  KERBOSCRIPT: {color: '#41adf0', name: 'KerboScript'},
  'KICAD LAYOUT': {color: '#2f4aab', name: 'KiCad Layout'},
  'KICAD LEGACY LAYOUT': {color: '#2f4aab', name: 'KiCad Legacy Layout'},
  'KICAD SCHEMATIC': {color: '#2f4aab', name: 'KiCad Schematic'},
  KOTLIN: {color: '#A97BFF', name: 'Kotlin'},
  LFE: {color: '#4C3023', name: 'LFE'},
  LLVM: {color: '#185619', name: 'LLVM'},
  LOLCODE: {color: '#cc9900', name: 'LOLCODE'},
  LSL: {color: '#3d9970', name: 'LSL'},
  LABVIEW: {color: '#fede06', name: 'LabVIEW'},
  LARK: {color: '#2980B9', name: 'Lark'},
  LASSO: {color: '#999999', name: 'Lasso'},
  LATTE: {color: '#f2a542', name: 'Latte'},
  LESS: {color: '#1d365d', name: 'Less'},
  LEX: {color: '#DBCA00', name: 'Lex'},
  LIGOLANG: {color: '#0e74ff', name: 'LigoLANG'},
  LILYPOND: {color: '#9ccc7c', name: 'LilyPond'},
  LIQUID: {color: '#67b8de', name: 'Liquid'},
  'LITERATE AGDA': {color: '#315665', name: 'Literate Agda'},
  'LITERATE COFFEESCRIPT': {color: '#244776', name: 'Literate CoffeeScript', wrap: true},
  'LITERATE HASKELL': {color: '#5e5086', name: 'Literate Haskell'},
  'LIVECODE SCRIPT': {color: '#0c5ba5', name: 'LiveCode Script'},
  LIVESCRIPT: {color: '#499886', name: 'LiveScript'},
  LOGTALK: {color: '#295b9a', name: 'Logtalk'},
  LOOKML: {color: '#652B81', name: 'LookML'},
  LUA: {color: '#000080', name: 'Lua'},
  LUAU: {color: '#00A2FF', name: 'Luau'},
  MATLAB: {color: '#e16737', name: 'MATLAB'},
  MAXSCRIPT: {color: '#00a6a6', name: 'MAXScript'},
  MDX: {color: '#fcb32c', name: 'MDX', wrap: true},
  MLIR: {color: '#5EC8DB', name: 'MLIR'},
  MQL4: {color: '#62A8D6', name: 'MQL4'},
  MQL5: {color: '#4A76B8', name: 'MQL5'},
  MTML: {color: '#b7e1f4', name: 'MTML'},
  MACAULAY2: {color: '#d8ffff', name: 'Macaulay2'},
  MAKEFILE: {color: '#427819', name: 'Makefile'},
  MAKO: {color: '#7e858d', name: 'Mako'},
  MARKDOWN: {color: '#083fa1', name: 'Markdown', wrap: true},
  MARKO: {color: '#42bff2', name: 'Marko'},
  MASK: {color: '#f97732', name: 'Mask'},
  MATHEMATICA: {color: '#dd1100', name: 'Mathematica'},
  MAX: {color: '#c4a79c', name: 'Max'},
  MERCURY: {color: '#ff2b2b', name: 'Mercury'},
  MERMAID: {color: '#ff3670', name: 'Mermaid'},
  MESON: {color: '#007800', name: 'Meson'},
  METAL: {color: '#8f14e9', name: 'Metal'},
  MINIYAML: {color: '#ff1111', name: 'MiniYAML'},
  MINIZINC: {color: '#06a9e6', name: 'MiniZinc'},
  MINT: {color: '#02b046', name: 'Mint'},
  MIRAH: {color: '#c7a938', name: 'Mirah'},
  MODELICA: {color: '#de1d31', name: 'Modelica'},
  'MODULA-2': {color: '#10253f', name: 'Modula-2'},
  'MODULA-3': {color: '#223388', name: 'Modula-3'},
  MOJO: {color: '#ff4c1f', name: 'Mojo'},
  'MONKEY C': {color: '#8D6747', name: 'Monkey C'},
  MOONBIT: {color: '#b92381', name: 'MoonBit'},
  MOONSCRIPT: {color: '#ff4585', name: 'MoonScript'},
  MOTOKO: {color: '#fbb03b', name: 'Motoko'},
  'MOTOROLA 68K ASSEMBLY': {color: '#005daa', name: 'Motorola 68K Assembly'},
  MOVE: {color: '#4a137a', name: 'Move'},
  MUSTACHE: {color: '#724b3b', name: 'Mustache'},
  NCL: {color: '#28431f', name: 'NCL'},
  NMODL: {color: '#00356B', name: 'NMODL'},
  'NPM CONFIG': {color: '#cb3837', name: 'NPM Config'},
  NWSCRIPT: {color: '#111522', name: 'NWScript'},
  NASAL: {color: '#1d2c4e', name: 'Nasal'},
  NEARLEY: {color: '#990000', name: 'Nearley'},
  NEMERLE: {color: '#3d3c6e', name: 'Nemerle'},
  NETLINX: {color: '#0aa0ff', name: 'NetLinx'},
  'NETLINX+ERB': {color: '#747faa', name: 'NetLinx+ERB'},
  NETLOGO: {color: '#ff6375', name: 'NetLogo'},
  NEWLISP: {color: '#87AED7', name: 'NewLisp'},
  NEXTFLOW: {color: '#3ac486', name: 'Nextflow'},
  NGINX: {color: '#009639', name: 'Nginx'},
  NIM: {color: '#ffc200', name: 'Nim'},
  NIT: {color: '#009917', name: 'Nit'},
  NIX: {color: '#7e7eff', name: 'Nix'},
  NOIR: {color: '#2f1f49', name: 'Noir'},
  NU: {color: '#c9df40', name: 'Nu'},
  NUMPY: {color: '#9C8AF9', name: 'NumPy'},
  NUNJUCKS: {color: '#3d8137', name: 'Nunjucks'},
  NUSHELL: {color: '#4E9906', name: 'Nushell'},
  'OASV2-JSON': {color: '#85ea2d', name: 'OASv2-json'},
  'OASV2-YAML': {color: '#85ea2d', name: 'OASv2-yaml'},
  'OASV3-JSON': {color: '#85ea2d', name: 'OASv3-json'},
  'OASV3-YAML': {color: '#85ea2d', name: 'OASv3-yaml'},
  OCAML: {color: '#ef7a08', name: 'OCaml'},
  'OMNET++ MSG': {color: '#a0e0a0', name: 'OMNeT++ MSG'},
  'OMNET++ NED': {color: '#08607c', name: 'OMNeT++ NED'},
  OBJECTSCRIPT: {color: '#424893', name: 'ObjectScript'},
  'OBJECTIVE-C': {color: '#438eff', name: 'Objective-C'},
  'OBJECTIVE-C++': {color: '#6866fb', name: 'Objective-C++'},
  'OBJECTIVE-J': {color: '#ff0c5a', name: 'Objective-J'},
  ODIN: {color: '#60AFFE', name: 'Odin'},
  OMGROFL: {color: '#cabbff', name: 'Omgrofl'},
  OPAL: {color: '#f7ede0', name: 'Opal'},
  'OPEN POLICY AGENT': {color: '#7d9199', name: 'Open Policy Agent'},
  'OPENAPI SPECIFICATION V2': {color: '#85ea2d', name: 'OpenAPI Specification v2'},
  'OPENAPI SPECIFICATION V3': {color: '#85ea2d', name: 'OpenAPI Specification v3'},
  OPENCL: {color: '#ed2e2d', name: 'OpenCL'},
  'OPENEDGE ABL': {color: '#5ce600', name: 'OpenEdge ABL'},
  OPENQASM: {color: '#AA70FF', name: 'OpenQASM'},
  OPENSCAD: {color: '#e5cd45', name: 'OpenSCAD'},
  'OPTION LIST': {color: '#476732', name: 'Option List'},
  ORG: {color: '#77aa99', name: 'Org', wrap: true},
  OVERPASSQL: {color: '#cce2aa', name: 'OverpassQL', wrap: true},
  OXYGENE: {color: '#cdd0e3', name: 'Oxygene'},
  OZ: {color: '#fab738', name: 'Oz'},
  P4: {color: '#7055b5', name: 'P4'},
  PDDL: {color: '#0d00ff', name: 'PDDL'},
  'PEG.JS': {color: '#234d6b', name: 'PEG.js'},
  PHP: {color: '#4F5D95', name: 'PHP'},
  PLSQL: {color: '#dad8d8', name: 'PLSQL'},
  PLPGSQL: {color: '#336790', name: 'PLpgSQL'},
  'POV-RAY SDL': {color: '#6bac65', name: 'POV-Ray SDL'},
  PACT: {color: '#F7A8B8', name: 'Pact'},
  PAN: {color: '#cc0000', name: 'Pan'},
  PAPYRUS: {color: '#6600cc', name: 'Papyrus'},
  PARROT: {color: '#f3ca0a', name: 'Parrot'},
  PASCAL: {color: '#E3F171', name: 'Pascal'},
  PAWN: {color: '#dbb284', name: 'Pawn'},
  PEP8: {color: '#C76F5B', name: 'Pep8'},
  PERL: {color: '#0298c3', name: 'Perl'},
  PICOLISP: {color: '#6067af', name: 'PicoLisp'},
  PIGLATIN: {color: '#fcd7de', name: 'PigLatin'},
  PIKE: {color: '#005390', name: 'Pike'},
  'PIP REQUIREMENTS': {color: '#FFD343', name: 'Pip Requirements'},
  PKL: {color: '#6b9543', name: 'Pkl'},
  PLANTUML: {color: '#fbbd16', name: 'PlantUML'},
  POGOSCRIPT: {color: '#d80074', name: 'PogoScript'},
  POLAR: {color: '#ae81ff', name: 'Polar'},
  PORTUGOL: {color: '#f8bd00', name: 'Portugol'},
  POSTCSS: {color: '#dc3a0c', name: 'PostCSS'},
  POSTSCRIPT: {color: '#da291c', name: 'PostScript'},
  POWERBUILDER: {color: '#8f0f8d', name: 'PowerBuilder'},
  POWERSHELL: {color: '#012456', name: 'PowerShell'},
  PRAAT: {color: '#c8506d', name: 'Praat'},
  PRISMA: {color: '#0c344b', name: 'Prisma'},
  PROCESSING: {color: '#0096D8', name: 'Processing'},
  PROCFILE: {color: '#3B2F63', name: 'Procfile'},
  PROLOG: {color: '#74283c', name: 'Prolog'},
  PROMELA: {color: '#de0000', name: 'Promela'},
  'PROPELLER SPIN': {color: '#7fa2a7', name: 'Propeller Spin'},
  PUG: {color: '#a86454', name: 'Pug'},
  PUPPET: {color: '#302B6D', name: 'Puppet'},
  PUREBASIC: {color: '#5a6986', name: 'PureBasic'},
  PURESCRIPT: {color: '#1D222D', name: 'PureScript'},
  PYRET: {color: '#ee1e10', name: 'Pyret'},
  PYTHON: {color: '#3572A5', name: 'Python'},
  'PYTHON CONSOLE': {color: '#3572A5', name: 'Python console'},
  'PYTHON TRACEBACK': {color: '#3572A5', name: 'Python traceback'},
  'Q#': {color: '#fed659', name: 'Q#'},
  QML: {color: '#44a51c', name: 'QML'},
  'QT SCRIPT': {color: '#00b841', name: 'Qt Script'},
  QUAKE: {color: '#882233', name: 'Quake'},
  QUICKBASIC: {color: '#008080', name: 'QuickBASIC'},
  R: {color: '#198CE7', name: 'R'},
  RAML: {color: '#77d9fb', name: 'RAML'},
  RBS: {color: '#701516', name: 'RBS'},
  RDOC: {color: '#701516', name: 'RDoc', wrap: true},
  REXX: {color: '#d90e09', name: 'REXX'},
  RMARKDOWN: {color: '#198ce7', name: 'RMarkdown', wrap: true},
  RON: {color: '#a62c00', name: 'RON'},
  RPGLE: {color: '#2BDE21', name: 'RPGLE'},
  RUNOFF: {color: '#665a4e', name: 'RUNOFF', wrap: true},
  RACKET: {color: '#3c5caa', name: 'Racket'},
  RAGEL: {color: '#9d5200', name: 'Ragel'},
  RAKU: {color: '#0000fb', name: 'Raku'},
  RASCAL: {color: '#fffaa0', name: 'Rascal'},
  RESCRIPT: {color: '#ed5051', name: 'ReScript'},
  REASON: {color: '#ff5847', name: 'Reason'},
  REASONLIGO: {color: '#ff5847', name: 'ReasonLIGO'},
  REBOL: {color: '#358a5b', name: 'Rebol'},
  'RECORD JAR': {color: '#0673ba', name: 'Record Jar'},
  RED: {color: '#f50000', name: 'Red'},
  'REGULAR EXPRESSION': {color: '#009a00', name: 'Regular Expression'},
  "REN'PY": {color: '#ff7f7f', name: "Ren'Py"},
  REZ: {color: '#FFDAB3', name: 'Rez'},
  RING: {color: '#2D54CB', name: 'Ring'},
  RIOT: {color: '#A71E49', name: 'Riot'},
  ROBOTFRAMEWORK: {color: '#00c0b5', name: 'RobotFramework'},
  ROC: {color: '#7c38f5', name: 'Roc'},
  ROFF: {color: '#ecdebe', name: 'Roff', wrap: true},
  'ROFF MANPAGE': {color: '#ecdebe', name: 'Roff Manpage', wrap: true},
  ROUGE: {color: '#cc0088', name: 'Rouge'},
  'ROUTEROS SCRIPT': {color: '#DE3941', name: 'RouterOS Script'},
  RUBY: {color: '#701516', name: 'Ruby'},
  RUST: {color: '#dea584', name: 'Rust'},
  SAS: {color: '#B34936', name: 'SAS'},
  SCSS: {color: '#c6538c', name: 'SCSS'},
  SPARQL: {color: '#0C4597', name: 'SPARQL'},
  SQF: {color: '#3F3F3F', name: 'SQF'},
  SQL: {color: '#e38c00', name: 'SQL'},
  SQLPL: {color: '#e38c00', name: 'SQLPL'},
  'SRECODE TEMPLATE': {color: '#348a34', name: 'SRecode Template'},
  STL: {color: '#373b5e', name: 'STL'},
  SVG: {color: '#ff9900', name: 'SVG'},
  SALTSTACK: {color: '#646464', name: 'SaltStack'},
  SASS: {color: '#a53b70', name: 'Sass'},
  SCALA: {color: '#c22d40', name: 'Scala'},
  SCAML: {color: '#bd181a', name: 'Scaml'},
  SCENIC: {color: '#fdc700', name: 'Scenic'},
  SCHEME: {color: '#1e4aec', name: 'Scheme'},
  SCILAB: {color: '#ca0f21', name: 'Scilab'},
  SELF: {color: '#0579aa', name: 'Self'},
  SHADERLAB: {color: '#222c37', name: 'ShaderLab'},
  SHELL: {color: '#89e051', name: 'Shell'},
  'SHELLCHECK CONFIG': {color: '#cecfcb', name: 'ShellCheck Config'},
  SHEN: {color: '#120F14', name: 'Shen'},
  'SIMPLE FILE VERIFICATION': {color: '#C9BFED', name: 'Simple File Verification'},
  SINGULARITY: {color: '#64E6AD', name: 'Singularity'},
  SLASH: {color: '#007eff', name: 'Slash'},
  SLICE: {color: '#003fa2', name: 'Slice'},
  SLIM: {color: '#2b2b2b', name: 'Slim'},
  SLINT: {color: '#2379F4', name: 'Slint'},
  SMPL: {color: '#c94949', name: 'SmPL'},
  SMALLTALK: {color: '#596706', name: 'Smalltalk'},
  SMARTY: {color: '#f0c040', name: 'Smarty'},
  SMITHY: {color: '#c44536', name: 'Smithy'},
  SNAKEMAKE: {color: '#419179', name: 'Snakemake'},
  SOLIDITY: {color: '#AA6746', name: 'Solidity'},
  SOURCEPAWN: {color: '#f69e1d', name: 'SourcePawn'},
  SQUIRREL: {color: '#800000', name: 'Squirrel'},
  STAN: {color: '#b2011d', name: 'Stan'},
  'STANDARD ML': {color: '#dc566d', name: 'Standard ML'},
  STARLARK: {color: '#76d275', name: 'Starlark'},
  STATA: {color: '#1a5f91', name: 'Stata'},
  STRINGTEMPLATE: {color: '#3fb34f', name: 'StringTemplate'},
  STYLUS: {color: '#ff6347', name: 'Stylus'},
  'SUBRIP TEXT': {color: '#9e0101', name: 'SubRip Text'},
  SUGARSS: {color: '#2fcc9f', name: 'SugarSS'},
  SUPERCOLLIDER: {color: '#46390b', name: 'SuperCollider'},
  'SURVEX DATA': {color: '#ffcc99', name: 'Survex data'},
  SVELTE: {color: '#ff3e00', name: 'Svelte'},
  SWAY: {color: '#00F58C', name: 'Sway'},
  SWEAVE: {color: '#198ce7', name: 'Sweave'},
  SWIFT: {color: '#F05138', name: 'Swift'},
  SYSTEMVERILOG: {color: '#DAE1C2', name: 'SystemVerilog'},
  'TI PROGRAM': {color: '#A0AA87', name: 'TI Program'},
  'TL-VERILOG': {color: '#C40023', name: 'TL-Verilog'},
  TLA: {color: '#4b0079', name: 'TLA'},
  TOML: {color: '#9c4221', name: 'TOML'},
  TSQL: {color: '#e38c00', name: 'TSQL'},
  TSV: {color: '#237346', name: 'TSV'},
  TSX: {color: '#3178c6', name: 'TSX'},
  TXL: {color: '#0178b8', name: 'TXL'},
  TACT: {color: '#48b5ff', name: 'Tact'},
  TALON: {color: '#333333', name: 'Talon'},
  TCL: {color: '#e4cc98', name: 'Tcl'},
  TEX: {color: '#3D6117', name: 'TeX', wrap: true},
  TERRA: {color: '#00004c', name: 'Terra'},
  'TERRAFORM TEMPLATE': {color: '#7b42bb', name: 'Terraform Template'},
  TEXTGRID: {color: '#c8506d', name: 'TextGrid'},
  'TEXTMATE PROPERTIES': {color: '#df66e4', name: 'TextMate Properties'},
  TEXTILE: {color: '#ffe7ac', name: 'Textile', wrap: true},
  THRIFT: {color: '#D12127', name: 'Thrift'},
  TOIT: {color: '#c2c9fb', name: 'Toit'},
  TURING: {color: '#cf142b', name: 'Turing'},
  TWIG: {color: '#c1d026', name: 'Twig'},
  TYPESCRIPT: {color: '#3178c6', name: 'TypeScript'},
  TYPESPEC: {color: '#4A3665', name: 'TypeSpec'},
  TYPST: {color: '#239dad', name: 'Typst'},
  'UNIFIED PARALLEL C': {color: '#4e3617', name: 'Unified Parallel C'},
  'UNITY3D ASSET': {color: '#222c37', name: 'Unity3D Asset'},
  UNO: {color: '#9933cc', name: 'Uno'},
  UNREALSCRIPT: {color: '#a54c4d', name: 'UnrealScript'},
  URWEB: {color: '#ccccee', name: 'UrWeb'},
  V: {color: '#4f87c4', name: 'V'},
  VBA: {color: '#867db1', name: 'VBA'},
  VBSCRIPT: {color: '#15dcdc', name: 'VBScript'},
  VCL: {color: '#148AA8', name: 'VCL'},
  VHDL: {color: '#adb2cb', name: 'VHDL'},
  VALA: {color: '#a56de2', name: 'Vala'},
  'VALVE DATA FORMAT': {color: '#f26025', name: 'Valve Data Format'},
  'VELOCITY TEMPLATE LANGUAGE': {color: '#507cff', name: 'Velocity Template Language'},
  VERILOG: {color: '#b2b7f8', name: 'Verilog'},
  'VIM HELP FILE': {color: '#199f4b', name: 'Vim Help File'},
  'VIM SCRIPT': {color: '#199f4b', name: 'Vim Script'},
  'VIM SNIPPET': {color: '#199f4b', name: 'Vim Snippet'},
  'VISUAL BASIC .NET': {color: '#945db7', name: 'Visual Basic .NET'},
  'VISUAL BASIC 6.0': {color: '#2c6353', name: 'Visual Basic 6.0'},
  VOLT: {color: '#1F1F1F', name: 'Volt'},
  VUE: {color: '#41b883', name: 'Vue'},
  VYPER: {color: '#2980b9', name: 'Vyper'},
  WDL: {color: '#42f1f4', name: 'WDL'},
  WGSL: {color: '#1a5e9a', name: 'WGSL'},
  'WEB ONTOLOGY LANGUAGE': {color: '#5b70bd', name: 'Web Ontology Language'},
  WEBASSEMBLY: {color: '#04133b', name: 'WebAssembly'},
  'WEBASSEMBLY INTERFACE TYPE': {color: '#6250e7', name: 'WebAssembly Interface Type'},
  WHILEY: {color: '#d5c397', name: 'Whiley'},
  WIKITEXT: {color: '#fc5757', name: 'Wikitext', wrap: true},
  'WINDOWS REGISTRY ENTRIES': {color: '#52d5ff', name: 'Windows Registry Entries'},
  'WITCHER SCRIPT': {color: '#ff0000', name: 'Witcher Script'},
  WOLLOK: {color: '#a23738', name: 'Wollok'},
  'WORLD OF WARCRAFT ADDON DATA': {color: '#f7e43f', name: 'World of Warcraft Addon Data'},
  WREN: {color: '#383838', name: 'Wren'},
  X10: {color: '#4B6BEF', name: 'X10'},
  XC: {color: '#99DA07', name: 'XC'},
  XML: {color: '#0060ac', name: 'XML'},
  'XML PROPERTY LIST': {color: '#0060ac', name: 'XML Property List'},
  XQUERY: {color: '#5232e7', name: 'XQuery'},
  XSLT: {color: '#EB8CEB', name: 'XSLT'},
  XMAKE: {color: '#22a079', name: 'Xmake'},
  XOJO: {color: '#81bd41', name: 'Xojo'},
  XONSH: {color: '#285EEF', name: 'Xonsh'},
  XTEND: {color: '#24255d', name: 'Xtend'},
  YAML: {color: '#cb171e', name: 'YAML'},
  YARA: {color: '#220000', name: 'YARA'},
  YASNIPPET: {color: '#32AB90', name: 'YASnippet'},
  YACC: {color: '#4B6C4B', name: 'Yacc'},
  YUL: {color: '#794932', name: 'Yul'},
  ZAP: {color: '#0d665e', name: 'ZAP'},
  ZIL: {color: '#dc75e5', name: 'ZIL'},
  ZENSCRIPT: {color: '#00BCD1', name: 'ZenScript'},
  ZEPHIR: {color: '#118f9e', name: 'Zephir'},
  ZIG: {color: '#ec915c', name: 'Zig'},
  ZIMPL: {color: '#d67711', name: 'Zimpl'},
  CRONTAB: {color: '#ead7ac', name: 'crontab'},
  EC: {color: '#913960', name: 'eC'},
  FISH: {color: '#4aae47', name: 'fish'},
  HOON: {color: '#00b171', name: 'hoon'},
  ICALENDAR: {color: '#ec564c', name: 'iCalendar'},
  JQ: {color: '#c7254e', name: 'jq'},
  KVLANG: {color: '#1da6e0', name: 'kvlang'},
  'MIRC SCRIPT': {color: '#3d57c3', name: 'mIRC Script'},
  MCFUNCTION: {color: '#E22837', name: 'mcfunction'},
  MDSVEX: {color: '#5f9ea0', name: 'mdsvex', wrap: true},
  MUPAD: {color: '#244963', name: 'mupad'},
  NANORC: {color: '#2d004d', name: 'nanorc'},
  NESC: {color: '#94B0C7', name: 'nesC'},
  OOC: {color: '#b0b77e', name: 'ooc'},
  Q: {color: '#0040cd', name: 'q'},
  RESTRUCTUREDTEXT: {color: '#141414', name: 'reStructuredText', wrap: true},
  SED: {color: '#64b970', name: 'sed'},
  TEMPL: {color: '#66D0DD', name: 'templ'},
  VCARD: {color: '#ee2647', name: 'vCard'},
  WISP: {color: '#7582D1', name: 'wisp'},
  XBASE: {color: '#403a40', name: 'xBase'},
}
