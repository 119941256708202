import {
  defaultDisplayDelimiters,
  defaultInlineDelimiters,
  type MarkdownMathOptions,
} from '@github-ui/markdown-math/options'
import {markedMath} from '@github-ui/markdown-math/marked'
import {remarkMath} from '@github-ui/markdown-math/remark'

import type {CopilotMarkdownExtension, SanitizeAttributeHook} from '../extension'
import {createElement} from 'react'

const allowMathRendererAttributes: SanitizeAttributeHook = (node, data) => {
  if (node.nodeName === 'MATH-RENDERER' && data.attrName.match(/^(data-.*|style|class)$/)) data.forceKeepAttr = true
}

const markedOptions: MarkdownMathOptions = {
  // ChatGPT uses escaped brackets with inside whitespace as math delimeters
  // Marked operates on the raw text which includes escaping backslashes
  displayDelimiters: [...defaultDisplayDelimiters, {open: /\\\[\s/, close: /\s\\\]/}],
  inlineDelimiters: [...defaultInlineDelimiters, {open: /\\\( /, close: / \\\)/}],
}

const remarkOptions: MarkdownMathOptions = {
  // Remark operates on the parsed text which does not include escaping backslashes
  displayDelimiters: [...defaultDisplayDelimiters, {open: /\[\s/, close: /\s\]/}],
  inlineDelimiters: [...defaultInlineDelimiters, {open: /\( /, close: / \)/}],
}

export default function mathExtension(): CopilotMarkdownExtension {
  return {
    marked: [markedMath(markedOptions)],
    sanitizer: {
      allowedTagNames: ['math-renderer'],
      attributeHook: allowMathRendererAttributes,
    },
    transformMarkdown: remarkMath(remarkOptions),
    reactComponents: {
      // React doesn't support `className` on custom elements (and rehype-react doesn't know this), so we have to
      // rename it to `class`.
      ['math-renderer' as 'div']: ({node, children, className, ...props}) =>
        createElement('math-renderer', {...props, class: className}, children),
    },
  }
}
